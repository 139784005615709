<template>
  <v-container fluid>
    <ALoader v-if="!services" />
    <v-row v-else>
      <v-col
        cols="12"
        sm="4"
        md="3"
        lg="2"
        :key="index"
        v-for="(service, index) of services"
        :class="{ 'd-none': !service.isAllowed }"
      >
        <v-card
          class="fill-height d-flex flex-column"
          :to="{
            name: 'PageServiceDashboard',
            params: { serviceKey: service.key },
          }"
          :color="
            service.isEnabled && service.isAllowed ? 'white' : 'grey lighten-4'
          "
          :disabled="!service.isEnabled || !service.isAllowed"
        >
          <v-card-title>{{ service.name }}</v-card-title>
          <v-card-text>
            <div>{{ service.description }}</div>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="service.isAllowed"
              color="primary"
              outlined
              :to="{
                name: 'PageServiceDashboard',
                params: { serviceKey: service.key },
              }"
              :disabled="!service.isEnabled"
              >View</v-btn
            >
            <v-btn v-else color="error" text>Restricted</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import Breadcrumb from "@/objects/breadcrumb";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      debugMode: (state) => state.debugMode.data,
      auth: (state) => state.auth.data,
      rolePermission: (state) => state.rolePermission.data,
      services: (state) => state.service.data.services,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    apiPermission: new Api(),
    apiTeam: new Api(),
    breadcrumb: new Breadcrumb(),
  }),
  created() {
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });

    var internalRoleSlugs = this.$_.map(this.auth.roles, "slug");
    this.apiPermission.setMethod(`POST`);
    this.apiPermission.setUrl(
      `${this.$service.crm}/v1/en/console/rolePermission`
    );
    this.apiPermission.setParams({
      internalRoleSlugs: JSON.stringify(internalRoleSlugs),
    });
    this.apiPermission.setCallbackCompleted((response) => {
      this.$store.commit("updateRolePermission", response);

      console.log(this.services)
    });

    this.apiPermission.fetch();
    this.apiTeam.setMethod("GET");
    this.apiTeam.setUrl(
      `${this.$service.sso}/v1/en/console/ssoTeam/list/teams/${this.auth.uuid}`
    );
    this.apiTeam.setCallbackCompleted((response) => {
      var internalUsersTeamUuid = response.data.map((team) => {
        return team.uuid;
      });
      this.$store.commit("updateTeam", internalUsersTeamUuid.join(","));
    });
    this.apiTeam.callbackError("this user is not belogs to any team");
    this.apiTeam.fetch();
  },
};
</script>
